import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import moment from 'moment';
import 'moment/locale/ko';

import { Pagination } from 'components/molecules';
import AppointmentDetail from './WaitingAppointmentDetail';

import { getNextWaitingPage, getPreviousWaitingPage } from 'redux/modules/appointment';

import { formatAge, formatDayPeriodTime, formatPageArray } from 'utils';

import { device } from 'styles/global/device';
import { appointmentListColors } from 'styles/global/color';
import { BodyColumn, Table, TableRow } from 'styles/table';
import { BasicBtn } from 'styles/button';

import { IActive } from 'types/styleTypes';
import { IAppointment, IAppointmentList } from 'types/payloadTypes';

interface WaitingTableProps {
  waitingList: IAppointmentList;
  isMobile: boolean;
  onEnter: (appointmentId: number) => void;
  onInvite: (appointmentId: number) => void;
  onShow: (
    e: React.MouseEvent<Element, MouseEvent>,
    appointmentId: number,
    appointmentDate?: string,
    patientName?: string,
  ) => void;
  openCooperationModal: () => void;
  targetAppointment: number;
}

function WaitingTable({
  waitingList,
  isMobile,
  onEnter,
  onInvite,
  onShow,
  openCooperationModal,
  targetAppointment,
}: WaitingTableProps) {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState<Array<number>>([]);

  const { appointmentArray, count } = waitingList;

  const getRemainingTime = (appointmentTime: string) => {
    const appointmentAt = moment(appointmentTime);
    const remainingMinutes = appointmentAt.diff(moment(), 'minutes');
    return remainingMinutes;
  };

  const getStatus = (statusCode: number) => {
    if (statusCode === 0) {
      return '상담대기';
    }

    if (statusCode === 2) {
      return '완료';
    }

    if (statusCode === 5) {
      return '상담중';
    }
  };

  const handleCooperationStatus = (isCooperation, guardian, symptomDesc) => {
    if (isCooperation) return '협진';
    if (guardian) return symptomDesc;

    return '일반';
  };

  const handleEntryText = (status, date) => {
    if (status === 0 && getRemainingTime(date) > 30) return getStatus(status);
    if (getRemainingTime(date) <= 30 && getRemainingTime(date) > 0) return `상담 ${getRemainingTime(date)}분 전`;

    return '상담입장';
  };

  const handleGuardianText = (guardian, isFirstTime) => {
    if (!!guardian && guardian !== 'guardian') return '면회';
    if (isFirstTime) return '초진';

    return '재진';
  };

  const handleGetPage = (page: number) => {
    if (currentPage === page) {
      return null;
    }
    setCurrentPage(page);
    if (page > currentPage) {
      dispatch(getNextWaitingPage());
    } else {
      dispatch(getPreviousWaitingPage());
    }
  };

  const handleNextPage = () => {
    dispatch(getNextWaitingPage());
  };

  const handlePreviousPage = () => {
    dispatch(getPreviousWaitingPage());
  };

  useEffect(() => {
    const tempPageArray = formatPageArray(count);
    setPageArray(tempPageArray);
    // eslint-disable-next-line
  }, [count]);

  return (
    <>
      <AppointmentTable>
        <thead>
          <tr>
            <th>접속</th>
            <th>상태</th>
            <th>시간</th>
            <th>지역</th>
            <th>
              이름
              {!isMobile && '(나이/성별)'}
            </th>
            <th>전화번호</th>
            <th>초진 / 재진</th>
            <th>일반 / 협진</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {appointmentArray.map((appointment: IAppointment, index: number) => {
            const {
              id,
              appointmentDate,
              hasPatientEntered,
              patientGender,
              dateOfBirth,
              patientName,
              patientPhone,
              status,
              isEmergency,
              patientDistrict,
              isFirstTime,
              patient,
              isCooperation,
              guardian,
              symptomDesc,
            } = appointment;

            return (
              <Fragment key={`appointment-${index}`}>
                <TableRow
                  active={targetAppointment === id}
                  emergency={isEmergency && status === 0}
                  onClick={(e) => onShow(e, id, appointmentDate, patientName)}
                >
                  <td>
                    <ConnectionStatus active={hasPatientEntered} status={isEmergency} />
                  </td>
                  <td>
                    <BodyColumn
                      active={targetAppointment === id}
                      nextAppointment={getRemainingTime(appointmentDate) <= 30 && getRemainingTime(appointmentDate) > 0}
                      waitingAppointment={getRemainingTime(appointmentDate) < 0}
                    >
                      {handleEntryText(status, appointmentDate)}
                    </BodyColumn>
                  </td>
                  <td>{formatDayPeriodTime(appointmentDate)}</td>
                  <td>
                    <BodyColumn active={targetAppointment === id}>{patientDistrict}</BodyColumn>
                  </td>
                  <td>
                    <BodyColumn borderLeft active={targetAppointment === id}>
                      {patientName}
                      {!isMobile && `(${formatAge(dateOfBirth)}/${patientGender === 'M' ? '남' : '여'})`}
                    </BodyColumn>
                  </td>
                  <td>
                    <BodyColumn borderLeft active={targetAppointment === id}>
                      {patientPhone}
                    </BodyColumn>
                  </td>
                  <td>
                    <BodyColumn borderLeft active={targetAppointment === id}>
                      {handleGuardianText(guardian, isFirstTime)}
                    </BodyColumn>
                  </td>
                  <td>
                    <CooperationStatus active={isCooperation}>
                      {handleCooperationStatus(isCooperation, guardian, symptomDesc)}
                    </CooperationStatus>
                  </td>
                  <td>
                    <AppointmentBtnWrap>
                      {!isMobile && (
                        <SendMsgBtn disabled={hasPatientEntered} name="invitePatient" onClick={() => onInvite(id)}>
                          대기 요청
                        </SendMsgBtn>
                      )}
                      <EnterBtn name="enterRoom" onClick={() => onEnter(id)}>
                        대기실 입장
                      </EnterBtn>
                    </AppointmentBtnWrap>
                  </td>
                </TableRow>
                {isEmergency && status === 0 && (
                  <tr>
                    <td colSpan={9}>
                      <EmergencyTreatmentMessage>
                        {'* 응급상담이 배치되었습니다. 바로 '}
                        <span>상담실</span>
                        {'에 입장해주세요 '}
                      </EmergencyTreatmentMessage>
                    </td>
                  </tr>
                )}
                {targetAppointment === id && (
                  <tr>
                    <td style={{ padding: 0 }}>
                      <LaptopAppointmentDetailSection>
                        <AppointmentDetail
                          appointmentId={id}
                          openCooperationModal={openCooperationModal}
                          parent="AppointmentDetailRow"
                          patientId={patient}
                          onStart={onEnter}
                        />
                      </LaptopAppointmentDetailSection>
                    </td>
                  </tr>
                )}
                {targetAppointment === id && (
                  <tr>
                    <td style={{ padding: 0 }}>
                      <MobileAppointmentDetailSection>
                        <AppointmentDetail
                          isMobile
                          showConnection
                          appointmentId={id}
                          openCooperationModal={openCooperationModal}
                          parent="AppointmentDetailColumn"
                          patientId={patient}
                        />
                      </MobileAppointmentDetailSection>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </AppointmentTable>
      <PaginationSection>
        {count > 10 && (
          <Pagination
            count={count}
            currentPage={currentPage}
            paginationArray={pageArray}
            onGetPage={handleGetPage}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
          />
        )}
      </PaginationSection>
    </>
  );
}

export default WaitingTable;

const PaginationSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const LaptopAppointmentDetailSection = styled.section`
  @media ${device.wideScreen} {
    display: none;
  }
  @media ${device.tabletMax} {
    display: none;
  }
`;

const MobileAppointmentDetailSection = styled.section`
  @media ${device.wideScreen} {
    display: none;
  }
  @media ${device.laptopMin} {
    display: none;
  }

  background-color: white;
`;

const AppointmentBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AppointmentTable = styled(Table)`
  width: 100%;
  margin-top: 32px;
  border-spacing: 0 10px;
  @media ${device.mobileMax} {
    margin-top: 24px;
  }

  thead tr {
    th {
      padding: 15px 0 15px;
      margin-bottom: 15px;
      @media ${device.mobileMax} {
        font-size: 12px;
      }
    }

    th:first-of-type {
      width: 5%;
      @media ${device.tabletMax} {
        display: none;
      }
    }

    th:nth-of-type(2) {
      width: 6%;
      @media ${device.tabletMax} {
        display: none;
      }
    }

    th:nth-of-type(3) {
      width: 7.6%;
      @media ${device.tabletMax} {
        width: 12%;
      }
      @media ${device.mobileMax} {
        width: 37%;
      }
    }

    th:nth-of-type(4) {
      width: 6%;
      @media ${device.tabletMax} {
        width: 7%;
      }
      @media ${device.mobileMax} {
        display: none;
      }
    }
    th:nth-of-type(5) {
      width: 10%;
      @media ${device.mobileMax} {
        width: 26%;
      }
    }

    th:nth-of-type(6) {
      width: 11%;
      @media ${device.tabletMax} {
        padding: 0;
        text-align: center;
      }
      @media ${device.mobileMax} {
        display: none;
      }
    }

    th:nth-of-type(7),
    th:nth-of-type(8) {
      width: 7%;
      @media ${device.tabletMax} {
        display: none;
      }
    }

    th:nth-of-type(9) {
      width: 37.5%;
      text-align: left;
      padding-left: 44.9px;
      @media ${device.mobileMax} {
        width: 37%;
      }
    }

    th:last-of-type {
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    margin-bottom: 8px;
    tr:last-of-type {
      margin-bottom: 0;
    }
  }

  tbody {
    display: block;
    tr {
      td {
        padding: 21px 0;
        @media ${device.mobileMax} {
          font-size: 14px;
        }
      }
      td:first-of-type {
        width: 5%;
        @media ${device.tabletMax} {
          display: none;
          width: 0;
          color: transparent;
          div {
            background-color: transparent;
          }
        }
      }

      td:nth-of-type(2) {
        width: 6%;
        @media ${device.tabletMax} {
          display: none;

          div {
            border-left: none;
          }
        }
      }

      td:nth-of-type(3) {
        width: 7.6%;
        @media ${device.tabletMax} {
          width: 12%;
        }
        @media ${device.mobileMax} {
          width: 37%;
        }
      }

      td:nth-of-type(4) {
        width: 6%;
        @media ${device.tabletMax} {
          width: 7%;
        }
        @media ${device.mobileMax} {
          display: none;
        }
      }
      td:nth-of-type(5) {
        width: 10%;
        @media ${device.mobileMax} {
          width: 26%;
        }
      }

      td:nth-of-type(6) {
        width: 11%;
        @media ${device.tabletMax} {
          padding: 0;
          text-align: center;
        }
        @media ${device.mobileMax} {
          display: none;
        }
      }

      td:nth-of-type(7),
      td:nth-of-type(8) {
        width: 7%;
        @media ${device.tabletMax} {
          display: none;
        }
      }

      td:nth-of-type(9) {
        width: 37.5%;
        text-align: left;
        padding-right: 40px;
        @media ${device.tabletMax} {
          width: 0;
          color: transparent;
          div {
            border-left: none;
          }
        }
        @media ${device.mobileMax} {
          width: 37%;
          padding-right: 10px;
        }
      }

      td:last-of-type {
      }
    }
  }
`;

const ConnectionStatus = styled.div<IActive>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: ${(props) => (!props.active ? appointmentListColors.offline : appointmentListColors.online)};
`;

const EnterBtn = styled(BasicBtn)`
  display: flex;
  padding: 6px 16px;
  color: var(--label-blue);
  border-radius: 3px;
  background-color: var(--main-blue-01);
  border: 1px solid var(--main-blue-02);
  :hover {
    background-color: var(--main-blue-02);
  }
`;

const SendMsgBtn = styled(EnterBtn)`
  margin-right: 8px;
  background-color: var(--sub-blue-01);
  border: 1px solid var(--sub-blue-06);
  color: var(--sub-blue-07);
  :disabled {
    background-color: var(--grayscale-02);
    border: 1px solid var(--grayscale-03);
    color: var(--grayscale-05);
  }
  :hover {
    background-color: var(--sub-blue-06);
  }
`;

const StartTreatmentMessage = styled.div`
  position: relative;
  padding: 5px 0 10px;
  font-family: NotoSansRegular;
  font-size: 16px;
  width: 100%;
  color: ${appointmentListColors.nextAppointmentBorder};
  display: flex;
  justify-content: flex-start;
  span {
    font-family: NotoSansBold;
    font-size: 16px;
    font-weight: bold;
  }
  @media ${device.tabletMax} {
    display: none;
  }
`;

const EmergencyTreatmentMessage = styled(StartTreatmentMessage)`
  color: ${appointmentListColors.emergencyText};
  span {
    font-weight: 500;
    margin-left: 5px;
  }
`;

const activeColor = css`
  border: 1px solid var(--sub-blue-04);
  color: var(--sub-blue-04);
`;

const CooperationStatus = styled.div<IActive>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 44px;
  border-radius: 4px;
  ${({ active }) => (active ? activeColor : '')}
`;
